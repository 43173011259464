<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox">
      <div class="joinBox findEmail">
        <h1>
          {{ $t("find-id-info-email") }}<br />
          <b> {{ email }}</b
          >{{ $t("find-id-info-email-2") }}
        </h1>
        <h3>
          {{ $t("find-id-info-contact-info") }}
        </h3>
        <div class="buttonWrap">
          <!--<button class="point large" @click="onReLogin">{{ $t("btn-re-login") }}</button>-->
          <button class="point large" @click="onReLogin">{{ $t("btn-re-login") }}</button>
          <!--<button class="large" @click="onFindPassword" >{{ $t("find-pw-title") }}</button>-->
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<style>
  .findEmail > h1{font-size: 1.4rem;}
  .findEmail > h1 > b{font-size: 1.6rem;color:#0080FF;}
  .findEmail > h3{font-size: 1.2rem;color:#969696;}
  .buttonWrap > button{background: #0080FF;border: 0;border-radius: 5px;}
</style>
<script>
import Footer from "@/components/Footer";
import router from "../../router";

export default {
  components: { Footer },
  data() {
    return {
      email: "",
      accountId: "",
      device : null,
    };
  },
  mounted() {
    this.accountId = this.$route.params.accountId;
    this.email = this.$route.query.accountId;
    this.device = this.$route.query.device;
  },
  methods: {
    onFindPassword(){
      var path = `/${this.accountId}/findPassword`;
      if(this.device != undefined && this.device != null && this.device != "") {
        path = path + "?device=" + this.device;
      }
      router.push({path : path})
      document.location.href = path;
    },
    onReLogin(){
      if (this.device == "ControllerApp" || this.device == "ViewApp") {
        window.Android.reLogin("로그인 이동");
        return;
      }
      router.push({path : `/${this.accountId}/login`})
      document.location.href = `/${this.accountId}/login`;
    },
    getAccountId() {
      this.email = this.$route.query.accountId;
    },
  },
};
</script>
